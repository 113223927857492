<template>
  <div class="admin-limit">
    <div class="title">
      <span class="s" style="color: #ee4d2d; border-color: #ee4d2d"
        >活动列表</span
      >
    </div>
    <div class="search-cell">
      <el-form
        size="mini"
        ref="form"
        :inline="true"
        :model="form"
        label-width="80px"
      >
        <el-form-item label="时间段" label-width="50px">
          <el-popover
            placement="bottom"
            width="500"
            style="height: 400px"
            trigger="click"
            v-model="show"
          >
            <div
              style="
                transform: scale(0.6);
                height: 627px;
                margin-top: -120px;
                display: flex;
                margin-left: -160px;
                margin-right: -165px;
                margin-bottom: -130px;
                border: 1px solid #eee;
              "
            >
              <el-calendar style="height: 200px" v-model="date"> </el-calendar>
              <div style="border: 1px solid #eee">
                <div
                  style="
                    width: 250px;
                    background: #f6f6f6;
                    padding: 13.5px 10px;
                    font-size: 18px;
                  "
                >
                  时段
                </div>
                <div>
                  <el-radio-group v-model="radio" @change="timeChange">
                    <div
                      style="
                        padding: 13.5px 10px;
                        border-bottom: 1px solid #eee;
                        width: 250px;
                      "
                      v-for="(item, i) in timeData"
                      :key="i"
                    >
                      <el-radio style="font-size: 18px" :label="i">
                        {{ item.date }}</el-radio
                      >
                    </div>
                  </el-radio-group>
                </div>
              </div>
            </div>
            <div slot="reference">
              <el-button v-if="!times" type="danger" icon="el-icon-date" plain
                >选择时间段</el-button
              >
              <el-input
                style="width: 200px"
                readonly
                v-else
                v-model="times"
                placeholder="请输入内容"
              ></el-input>
              <!-- <span v-else style="font-size: 12px;cursor: pointer">{{
                times
              }}</span> -->
            </div>
          </el-popover>
        </el-form-item>
        <el-form-item label="活动状态">
          <el-select
            style="width: 120px"
            v-model="form.status"
            placeholder="全部"
          >
            <el-option label="全部" :value="0"></el-option>
            <el-option label="进行中" :value="1"></el-option>
            <el-option label="未开始" :value="2"></el-option>
            <el-option label="已结束" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开启状态">
          <el-select
            style="width: 120px"
            v-model="form.type"
            placeholder="全部"
          >
            <el-option label="全部" :value="0"></el-option>
            <el-option label="开启" :value="1"></el-option>
            <el-option label="关闭" :value="2"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="所属学校">
          <el-select
            style="width: 120px"
            v-model="form.school_id"
            placeholder="全部"
            @change="getClassLt"
          >
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item in schoolList"
              :key="item.id"
              :label="item.school_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属班级">
          <el-select
            style="width: 120px"
            v-model="form.class_id"
            placeholder="全部"
          >
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="item in classList"
              :key="item.id"
              :label="item.class_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="
              page = 1;
              getList();
            "
            >查询</el-button
          >
          <el-button plain @click="handleReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="tab">
      <el-table
        size="small "
        v-loading="loading"
        border
        :data="tableData.list"
        style="width: 100%; text-algin: center"
        :header-cell-style="{ background: '#F2F2F2', 'border-color': '#000' }"
        :row-style="{ 'border-color': '#000' }"
        :cell-style="{ 'border-color': '#000' }"
        :header-row-style="{ 'border-color': '#000' }"
      >
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="id"
          label="序号"
          width="70"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="deal_time"
          label="选购时段"
          width="180"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="open_number"
          label="开启数量"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="state_txt"
          label="活动状态"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="status"
          label="开启状态"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.status == 1 ? "开启" : "关闭" }}</span>
          </template>
        </el-table-column>

        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="shop_name"
          label="店铺名称"
        >
        </el-table-column>

        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="school_name"
          label="所属学校"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="class_name"
          label="所属班级"
        >
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          align="center"
          prop="student_code"
          label="操作"
        >
          <template slot-scope="scope">
            <span class="btn" @click="handleLook(scope.row)">查看</span>
            <span
              class="btn"
              @click="handleClose(scope.row)"
              v-if="scope.row.state == 1"
              >结束</span
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        small
        style="margin-top: 20px"
        background
        layout="prev, pager, next"
        :page-size="page_size"
        :total="tableData.total"
        :current-page="page"
        @current-change="pageChange"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import {
  getSchoolList,
  getClassList,
  limitList,
  limitFinish,
} from "@/api/admin.js";
export default {
  data() {
    return {
      form: {
        name: "",
        goods_name: "",
        shop_name: "",
        type: 0,
        status: 0,
        school_id: "",
        class_id: "",
        start_time: "",
        end_time: "",
        time: "",
      },
      value: 0,
      keyWord: "",
      options: ["促销名称", "商品名称", "店铺名称"],
      schoolList: [],
      classList: [],
      tableData: { list: [] },
      page: 1,
      page_size: 10,
      times: "",
      timeShow: false,
      timeData: [
        { date: "00:00:00-09:00:00", fa: "00:00-09:00", num: 10, type: false },
        { date: "09:00:00-12:00:00", fa: "09:00-12:00", num: 10, type: false },
        { date: "12:00:00-14:00:00", fa: "12:00-14:00", num: 10, type: false },
        { date: "14:00:00-16:00:00", fa: "14:00-16:00", num: 10, type: false },
        { date: "16:00:00-20:00:00", fa: "16:00-20:00", num: 10, type: false },
        { date: "20:00:00-22:00:00", fa: "20:00-22:00", num: 10, type: false },
        { date: "22:00:00-00:00:00", fa: "22:00-00:00", num: 10, type: false },
      ],
      radio: "",
      date: "",
      show: false,
      time: "",
      loading: true,
    };
  },
  created() {
    this.getCampusList();
    this.getClassLt();
    this.getList();
  },
  watch: {
    date: function (newVal) {
      // let time = new Date(newVal).getTime();
      // if (time >= this.currentTime && time <= this.afterTime) {
      //   this.timeShow = true;
      // } else {
      //   this.timeShow = false;
      // }
      this.radio = "";
      // let ks = parseInt((newVal.getTime() - 3600000 * 8) / 1000);
      // let js = parseInt(ks + 3600 * 24);
      this.time = this.switchDate(newVal);
    },
  },
  methods: {
    getList() {
      this.loading = true;
      if (this.value == 0) {
        this.form.name = this.keyWord;
      }
      if (this.value == 1) {
        this.form.shop_name = this.keyWord;
      }

      limitList({
        ...this.form,
        page: this.page,
        page_size: this.page_size,
      }).then((res) => {
        this.loading = false;
        if (res.code == 1) {
          this.tableData = res.data;
        }
      });
    },
    switchDate(standardTime) {
      if (standardTime != "") {
        let a = standardTime;
        var Month = a.getMonth() + 1;
        var MonthDate;
        var DayDate = a.getDate();
        var Day;
        if (Month < 10) {
          MonthDate = "0" + Month;
        } else {
          MonthDate = Month;
        }
        if (DayDate < 10) {
          Day = "0" + DayDate;
        } else {
          Day = DayDate;
        }
        var datetime = a.getFullYear() + "-" + MonthDate + "-" + Day;
        return datetime;
      }
    },
    getCampusList() {
      getSchoolList().then((res) => {
        if (res.code == 1) {
          this.schoolList = res.data;
        }
      });
    },
    getClassLt() {
      this.form.class_id = "";
      getClassList({
        school_id: this.form.school_id,
      }).then((res) => {
        this.classList = res.data;
      });
    },
    handleTimeChange() {
      this.form.start_time = parseInt(this.form.time[0].getTime() / 1000);
      this.form.end_time = parseInt(this.form.time[1].getTime() / 1000);
    },

    handleReset() {
      this.form = {
        name: "",
        shop_name: "",
        type: 0,
        status: 0,
        school_id: "",
        class_id: "",
        start_time: "",
        end_time: "",
        time: "",
      };
      this.times = "";
      this.radio = "";
      this.value = 0;
      this.keyWord = "";
      this.page = 1;
      this.getList();
      this.getClassLt();
    },
    pageChange(index) {
      this.page = index;
      this.getList();
    },
    handleLook(val) {
      sessionStorage.ADMIN_ACTIVITYID = val.id;
      this.$router.push("adminLimitInfo");
    },
    timeChange() {
      this.times = this.time + " " + this.timeData[this.radio].date;
      this.form.start_time =
        new Date(
          this.time + " " + this.timeData[this.radio].date.split("-")[0]
        ).getTime() / 1000;
      this.form.end_time =
        new Date(
          this.time + " " + this.timeData[this.radio].date.split("-")[1]
        ).getTime() / 1000;
      if (this.radio == 6) {
        this.form.end_time = this.form.end_time + 86400;
      }
      this.show = false;
    },
    handleClose(val) {
      this.$confirm(
        "结束活动后,店铺活动状态变更为已结束, 是否确定结束?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        limitFinish({
          id: val.id,
        }).then((res) => {
          if (res.code == 1) {
            this.$message.success(res.message);
            val.status_txt = "已结束";
            val.status = 3;
          }
        });
      });
    },
  },
};
</script>
<style lang="less" >
.admin-limit {
  background: #fff;
  padding: 20px;
  .title {
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    .s {
      font-size: 14px;
      border-bottom: 2px solid transparent;
      padding: 0 10px;
      padding-bottom: 10px;
      cursor: pointer;
    }
  }
  .search-cell {
    padding: 20px 0 0;
  }
  .tab {
    .btn {
      font-size: 12px;
      cursor: pointer;
      margin: 0 10px;
    }
    .btn:hover {
      border-bottom: 1px solid #000;
    }
  }
}
.el-form-item__label {
  font-size: 12px;
}
.el-radio__label {
  font-size: 16px;
}
</style>